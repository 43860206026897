// Libs.
import React from 'react';
// Deps.
import ContactUs from '../components/contact-us/contact-us';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import crmClientService from '../services/crmClientService';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

const ContactUsPage = () => {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <ContactUs />
      <GoogleReCaptcha action="contactUs" onVerify={crmClientService.v3Callback} />
    </Layout>
  );
};

export default ContactUsPage;
